<template>
  <div class="wh-space-bottom">
    <b-card>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </b-card>

    <b-card>
      <table-header
        ref="header"
        :title="title"
        :hide-search="false"
        :searchTips="$t('geo_fencing.record_search_tips')"
        :button-actions="header_actions"
        @runSearch="runSearch"
      />
      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        :permission="view_permission"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
      >
        <template #confirming-status="s">
          <span
            v-if="s.props.formattedRow[s.props.column.field] === true"
            v-html="s.props.column.changeHTML(s.props.formattedRow[s.props.column.field], s.props.row)"
          >
          </span>
          <div v-else>
            <button-with-dropdown
              size="sm"
              :options="[
                {text: common.getI18n('geo_fencing.confirm_this_time'), tag: 'confirmThisTime', disabled: !common.checkPermission(('GeoFencing_Confirm_Record'))},
                {text: common.getI18n('geo_fencing.confirm_this_person'), tag: 'confirmThisPerson', disabled: !common.checkPermission('GeoFencing_Confirm_Record')}
               ]"
              @click="(tag) => handleConfirm(tag, s.props)"
            ></button-with-dropdown>
          </div>
        </template>
      </table-content>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import ButtonWithDropdown from '@/components/ButtonWithDropdown'

export default {
  name: 'callBellRecord',
  components: {
    TableSearch,
    TableHeader,
    TableContent,
    ButtonWithDropdown,
  },
  data() {
    return {
      title: common.getMenuName('geoFencingRecord'),
      view_permission: common.checkPermission('GeoFencing_Menu_Record'),
      common,
      // view_permission: false,
      header_actions: [{
        text: 'geo_fencing.confirm_all',
        variant: 'danger',
        permission: 'GeoFencing_Confirm_Record',
        fun: () => {
          common.showConfirm({
            title: this.$t('geo_fencing.confirm_all_tips'),
            confirm_fun: async () => {
              await this.$requestWehealth({
                method: 'post',
                url: '/geofencing/ConfirmAllArmeds',
              })
              // common.showToast({ title: this.$t('common.delete_success') })
              this.getList()
            }
          })
        },
      }],
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'member.chineseName',
          field: 'memberName',
          width: '180px',
          changeHTML: function (value, row) {
            let avatar
            if (row.memberPhoto) {
              avatar = common.getServerUrl() + row.memberPhoto
            } else {
              avatar = require('@/assets/images/user.png')
            }
            return `
              <div class="d-flex align-items-center">
                <div class="b-avatar badge-secondary rounded-circle default-photo-bg mr-05">
                  <span class="b-avatar-img">
                    <img src="${avatar}" alt="">
                  </span>
                </div>
                <div>${value}</div>
              </div>
            `
          }
        },
        {
          label: 'common.floor',
          field: 'floorName',
          width: '110px',
        },
        {
          label: 'common.room',
          field: 'roomName',
          width: '110px',
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          width: '110px',
        },
        {
          label: 'geo_fencing.antenna_name',
          field: 'location',
          width: '150px',
        },
        {
          label: 'call_bell.prompt_time',
          field: 'armedTime',
          width: '190px',
          change: this.changeTimeFormat,
        },
        {
          label: 'call_bell.follow_up_time',
          field: 'followedTime',
          width: '190px',
          change: this.changeTimeFormat,
        },
        {
          label: 'call_bell.follow_up_user',
          field: 'followedStaff',
          width: '110px',
        },
        {
          label: 'call_bell.confirm_status',
          field: 'isConfirmed',
          width: '190px',
          type: 'slot',
          slot: 'confirming-status',
          changeHTML: (value, _row) => {
            return value ? this.$t('call_bell.confirm_status_positive') : this.$t('call_bell.confirm_status_pending')
          }
        },
        // {
        //   label: 'call_bell.confirm_status',
        //   field: 'isConfirmed',
        //   width: '100px',
        //   // change: (isConfirmed) => isConfirmed ? 'true' : 'false',
        //   changeHTML: (value, _row) => {
        //     return value ? this.$t('call_bell.confirm_status_positive') : this.$t('call_bell.confirm_status_pending')
        //   }
        // },
        // {
        //   label: 'common.action',
        //   field: 'action',
        //   width: '240px',
        //   sortable: false,
        // },
      ],
      table_actions: [
        // {
        //   text: 'geo_fencing.confirm_this_time',
        //   variant: 'primary',
        //   // fun: 'editData',
        //   // permission: 'GeoFencing_Menu_Record'
        //   show: (row) => !row.isConfirmed
        // },
        // {
        //   text: 'geo_fencing.confirm_this_person',
        //   variant: 'danger',
        //   // fun: 'deleteData',
        //   // permission: 'GeoFencing_Menu_Record'
        //   show: (row) => !row.isConfirmed
        // }
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: [],
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
        {
          field: 'isConfirmed',
          label: 'call_bell.confirm_status',
          type: 'select',
          options: [{
            text: this.$t('call_bell.confirm_status_positive'),
            value: true
          }, {
            text: this.$t('call_bell.confirm_status_pending'),
            value: false
          }],
          clearable: true,
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId()
      },
    }
  },

  computed: {
    geoFencingToastNum: () => store.state.app.geoFencingToastNum,
  },

  watch: {
    geoFencingToastNum(newValue, oldValue) {
      if(newValue === 0 && oldValue > 0) {
        this.getList()
      }
    }
  },

  mounted() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
      this.getList()
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
      this.getList()
    }
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      this.$requestWehealth({
        method: 'get',
        url: '/geofencing/GetArmedList',
        params: Object.assign({}, this.getSearchCondition(), this.sort_condition, this.page_condition)
      })
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.geoFencings
        })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()
      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      Object.assign(where, this.$refs.search.search_condition)
      return where
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    changeTimeFormat(time) {
      if (time === null || time === undefined) {
        return ''
      } else {
        return this.$moment(time)
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
    async handleConfirm(tag, props) {
      if (tag === 'confirmThisTime') {
        await this.$requestWehealth({
          method: 'post',
          url: '/geofencing/ConfirmArmed',
          params: {
            armedID: props.row.armedID
          }
        })
        // common.showToast({ title: this.$t('common.delete_success') })
        this.getList()
      } else if (tag === 'confirmThisPerson') {
        common.showConfirm({
          title: this.$t('geo_fencing.confirm_this_person_tips').replace('{member}', `${props.formattedRow.memberName}`),
          confirm_fun: async () => {
            await this.$requestWehealth({
              method: 'post',
              url: '/geofencing/ConfirmMemberArmeds',
              params: {
                memberID: props.row.memberID
              }
            })
            // common.showToast({ title: this.$t('common.delete_success') })
            this.getList()
          }
        })
      }
    }
  }
}
</script>
